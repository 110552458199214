
import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
        path: '/login',
        redirect: '/index'
    },
    {
      path: '/index',
      component: () => import('@/WebLogin'),
      meta: {
        title: '首页'
      },
    },
    {
        path: '/googleAuth',
        component: () => import('@/GoogleAuthPage'),
        meta: {
          title: '绑定Google验证器'
        },
      },
  ]
});

export default router;

